:root {
  --color-background: #FFFFFF;

  --color-primary: #E0055B;

  --color-secondary: #E0055B;

  --color-success-primary: #1FB853;
  --color-success-secondary: #DDFFE6;

  --color-error-primary: #E32324;
  --color-error-secondary: #FCD9DA;

  --text-color-primary: #040402;
  --text-color-secondary: #7D8186;
  --text-color-tertiary: #2A2A2A;

  --color-gray-100: #FBFBFB;
  --color-gray-200: #E6EAEC;
  --color-gray-300: #FAF8F3;
  --color-gray-400: #BDBDBD;
  --color-gray-500: #9E9E9E;
  --color-gray-600: #6A7380;
  --color-gray-700: #616161;
  --color-gray-900: #40404059;

  --color-pink-400: #E1015C;

  --color-red-400: #E10101;

  --color-black: #0D0D0D;

  --color-white: #FFFFFF;
}