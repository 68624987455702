.button {
    cursor: pointer !important;

    border: none;
    border-radius: 2rem !important;

    font-size: 1rem;

    text-transform: uppercase;
}

.button--primary {
    @extend .button;

    background-color: var(--color-secondary) !important;
    color: var(--color-white) !important;

    &:disabled {
        opacity: 0.7;
        cursor: default !important;
    }
}

.button--secondary {
    @extend .button;

    background-color: var(--color-white) !important;
    color: var(--text-color-secondary) !important;
    border: 1px solid var(--text-color-secondary) !important;

    &__right-spacing {
        @extend .button--secondary;
        margin-right: 1rem;
    }
}

.button--outline {
    @extend .button;

    background-color: var(--color-white) !important;
    color: var(--color-secondary) !important;
    border: 1px solid var(--color-secondary) !important;

    &__right-spacing {
        @extend .button--outline;
        margin-right: 1rem;
    }
}
