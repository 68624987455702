@use 'colors';

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '~@nebular/theme/styles/globals';
@import '~nebular-icons/css/nebular-icons.css';
@import '~@nebular/auth/styles/globals';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
};

@import "./range-input";
@import "./buttons";
@import "./modals";

html, body {
  height: 100%;
}

html *, body * {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
}

.mat-cell, .mat-header-cell {
  padding: 1rem .5rem !important;
}

.long-text {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.long-text-missions {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25vw;
  min-width: 15rem;
  white-space: nowrap;
}

nb-card {
  box-shadow: 0 0 1rem 0 rgba(44, 51, 73, 0.1);
  border: none;
  border-radius: .7rem;
}

.container-md, .container-sm, .container-is, .container {
  @media (min-width: 400px) {
    max-width: 100% !important;
  }

  @media (min-width: 576px) {
    width: 100% !important;
  }

  @media (min-width: 768px) {
    width: 100% !important;
  }

  @media (min-width: 1200px) {
    width: 100% !important;
  }
}

* {
  &::-webkit-scrollbar {
    width: var(--layout-scrollbar-width);
    height: var(--layout-scrollbar-width);
  }

  &::-webkit-scrollbar-corner {
    background: var(--layout-scrollbar-background-color);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--layout-scrollbar-color);
    cursor: pointer;
    border-radius: calc(var(--layout-scrollbar-width) * 0.5);
  }

  &::-webkit-scrollbar-track {
    background: var(--layout-scrollbar-background-color);
  }
}

th:first-of-type {
  div.mat-sort-header-container {
    justify-content: flex-start !important;
  }
}
.mat-sort-header-container {
  justify-content: center !important;
}

.mat-sort-header-arrow {
  margin-right: -18px !important;
}

td, th {
  text-align: center !important;
  min-width: 6rem;

  &:first-of-type {
    text-align: start !important;
  }
}

.table {
  > .tr {
    display: grid;
    grid-template-columns: minmax(12rem, 1fr) 7rem minmax(6rem, 7rem) 7rem;
    width: 100%;
    padding-left: 5px;

    > .overflow {
      text-overflow: ellipsis;
    }

    > .td {
      padding-right: 1rem;
      padding-bottom: .5rem;
      padding-top: .5rem;

      &:first-of-type {
        width: 100%;
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .7rem 1rem;
        box-shadow: 1px 1px 5px #5c585855;
        white-space: nowrap;
        border-radius: .7rem;

        > .overflow-td-text {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.actions {
          display: flex;
          justify-content: space-between;
          padding: .7rem 0;
          gap: .5rem;
        }

        nb-icon[icon="trash-2-outline"] {
          color: red;
          min-width: 1.2rem;
        }
      }

      &:last-of-type {
        > div {
          box-shadow: none;
          border-radius: 0;
        }
      }
    }
  }
}

input, textarea {
  &.validator, &.validator:hover, &.validator:focus  {
    border: 1px solid var(--color-primary) !important;
    --input-basic-hover-border-color: var(--color-primary) !important;
    --input-basic-focus-border-color: var(--color-primary) !important;
  }
}
nb-user {
  cursor: pointer;
  color: white;

  .user-container{
    .user-picture.image{
      background-position: center;
    }
  }
}

nb-select.appearance-outline.status-basic .select-button:focus{
  border-color: var(--select-outline-basic-border-color) !important;
}

select {
  width: 100%;
  height: 40px;
  background: white;
  outline: none;
  padding: 7px 35px 7px 18px;
  border: 1px solid var(--select-outline-basic-border-color);
  font-size: var(--option-medium-text-font-size);
  font-weight: var(--option-medium-text-font-weight);
  line-height: var(--option-medium-text-line-height);
  padding: var(--option-medium-padding);

  option {
    border: 1px solid var(--select-outline-basic-border-color);
    font-size: var(--option-medium-text-font-size);
    font-weight: var(--option-medium-text-font-weight);
    line-height: var(--option-medium-text-line-height);
    padding: var(--option-medium-padding);
  }
}

.th-bold {
  color: #737373;
  font-weight: 900;
}

