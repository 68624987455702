.range {
  width: 400px;
  height: 15px;
  -webkit-appearance: none;
  background: #d2d2d2;
  outline: none;
  border-radius: 15px;
  overflow: hidden;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--color-secondary);
  cursor: pointer;
  border: 3px solid #ffffff;
  box-shadow: -407px 0 0 400px var(--color-secondary);
}
