// @nebular theming framework
@import '~@nebular/theme/styles/theming';
// @nebular out of the box themes
@import '~@nebular/theme/styles/themes';

:host {
  --color-album-left: linear-gradient(90deg, rgba(255,222,195,1) 81%, rgba(255,196,148,1) 97%, rgba(234,168,114,1) 100%);
  --color-album-right: linear-gradient(90deg, rgba(234,168,114,1) 0%, rgba(255,196,148,1) 3%, rgba(255,222,195,1) 19%);
}

$nb-themes: nb-register-theme((
  color-primary-500: var(--color-secondary),
  popover-text-font-size: 0.856rem,
  popover-shadow: 0px 0px 3px rgb(0 0 0 / 27%),
  radio-width: 0.9rem,
  radio-height: 0.9rem,
  radio-text-font-size: 0.8rem,
  radio-text-font-weight: 400,
  radio-basic-checked-border-color: var(--color-secondary),
  radio-basic-inner-circle-color: var(--color-secondary),
  radio-basic-focus-inner-circle-color: var(--color-secondary),
  radio-basic-hover-border-color: var(--color-secondary),
  radio-basic-hover-inner-circle-color: var(--color-secondary),
  radio-basic-active-border-color: var(--color-secondary),
  radio-basic-active-inner-circle-color: var(--color-secondary),
  radio-primary-border-color: yellow,
  toggle-width: 1.8rem,
  toggle-height: 0.8rem,
  toggle-switcher-size: 0.68rem,
  toggle-border-width: 2px,
  toggle-basic-background-color: #fff,
  toggle-basic-hover-border-color: var(--color-secondary),
  toggle-basic-hover-checked-background-color: #fff,
  toggle-basic-active-border-color: #8f9bb3,
  toggle-basic-active-checked-background-color: #8f9bb3,
  toggle-basic-hover-background-color: #fff,
  toggle-basic-checked-switcher-background-color: var(--color-secondary),
  toggle-basic-focus-background-color: #fff,
  toggle-basic-focus-checked-background-color: #fff,
  toggle-basic-focus-checked-border-color: var(--color-secondary),
  toggle-basic-active-checked-border-color    : #fff,
  toggle-basic-hover-checked-border-color: var(--color-secondary),
  toggle-basic-checked-background-color: #fff,
  toggle-basic-checked-border-color: var(--color-secondary),
  toggle-basic-focus-border-color: #8f9bb3,
  toggle-switcher-icon-size: 0rem,
  select-outline-basic-background-color: #fff,
  layout-padding-top: 2.25rem,
  menu-item-icon-margin: 0 0.5rem 0 0,
  card-height-tiny: 13.5rem,
  card-height-small: 21.1875rem,
  card-height-medium: 28.875rem,
  card-height-large: 36.5625rem,
  card-height-giant: 44.25rem,
  card-margin-bottom: 1.875rem,
  card-header-with-select-padding-top: 0.5rem,
  card-header-with-select-padding-bottom: 0.5rem,
  select-min-width: 6rem,
  slide-out-background: #f7f9fc,
  slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
  slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
  menu-item-divider-color: none,
  sidebar-width-compact: 5rem,
  menu-text-color: #8f9bb3,
  menu-item-icon-width: 1.3rem,
  menu-item-padding: 0.5rem 1rem,
  menu-item-hover-text-color: var(--color-secondary),
  menu-item-active-text-color: var(--color-secondary),
  input-basic-focus-border-color: #e4e9f2,
  tooltip-background-color: var(--color-secondary),
), default, default);

$nb-themes: nb-register-theme((
  layout-padding-top: 2.25rem,
  menu-item-icon-margin: 0 0.5rem 0 0,
  card-height-tiny: 13.5rem,
  card-height-small: 21.1875rem,
  card-height-medium: 28.875rem,
  card-height-large: 36.5625rem,
  card-height-giant: 44.25rem,
  card-margin-bottom: 1.875rem,
  card-header-with-select-padding-top: 0.5rem,
  card-header-with-select-padding-bottom: 0.5rem,
  select-min-width: 6rem,
  slide-out-background: #252547,
  slide-out-shadow-color: 2px 0 3px #29157a,
  slide-out-shadow-color-rtl: -2px 0 3px #29157a,
), cosmic, cosmic);

$nb-themes: nb-register-theme((
  layout-padding-top: 2.25rem,
  menu-item-icon-margin: 0 0.5rem 0 0,
  card-height-tiny: 13.5rem,
  card-height-small: 21.1875rem,
  card-height-medium: 28.875rem,
  card-height-large: 36.5625rem,
  card-height-giant: 44.25rem,
  card-margin-bottom: 1.875rem,
  card-header-with-select-padding-top: 0.5rem,
  card-header-with-select-padding-bottom: 0.5rem,
  select-min-width: 6rem,
  slide-out-background: linear-gradient(270deg, #edf1f7 0%, #e4e9f2 100%),
  slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
  slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
), corporate, corporate);

$nb-themes: nb-register-theme((
  background-basic-color-1: '#00c16d',
  background-basic-color-2: '#00c16d',
  background-basic-color-3: '#00c16d',
  background-basic-color-4: '#00c16d',
  layout-padding-top: 2.25rem,
  menu-item-icon-margin: 0 0.5rem 0 0,
  card-height-tiny: 13.5rem,
  card-height-small: 21.1875rem,
  card-height-medium: 28.875rem,
  card-height-large: 36.5625rem,
  card-height-giant: 44.25rem,
  card-margin-bottom: 1.875rem,
  card-header-with-select-padding-top: 0.5rem,
  card-header-with-select-padding-bottom: 0.5rem,
  select-min-width: 6rem,
  slide-out-background: linear-gradient(270deg, #222b45 0%, #151a30 100%),
  slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
  slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
), dark, dark);
